<button mat-flat-button color="primary" (click)="addFolder()">Add</button>

    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="folder">
            <th mat-header-cell *matHeaderCellDef> Folder </th>
            <td mat-cell *matCellDef="let element"> {{element.folder}} </td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef class="no-mobile"> From </th>
            <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.searchTerms.fromTerm}} </td>
        </ng-container>

        <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef> Subject </th>
            <td mat-cell *matCellDef="let element"> {{element.searchTerms.subject}} </td>
        </ng-container>

        <ng-container matColumnDef="body">
            <th mat-header-cell *matHeaderCellDef class="no-mobile"> Body </th>
            <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.searchTerms.body}} </td>
        </ng-container>

        <ng-container matColumnDef="dataFormat">
            <th mat-header-cell *matHeaderCellDef > Format </th>
            <td mat-cell *matCellDef="let element" > {{element.format?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-align-center"> Actions </th>
            <td mat-cell *matCellDef="let element" class="text-align-center">
                <button mat-icon-button (click)="edit(element)" color="primary">
                    <mat-icon class="icons" aria-hidden="false" aria-label="Edit">edit</mat-icon>
                </button>

                <button mat-icon-button (click)="openDeleteDialog(element)" [disabled]="element.enabled" color="primary">
                    <mat-icon class="icons" aria-hidden="false" aria-label="Delete" >
                        delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
