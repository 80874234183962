import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { DataFormat } from 'src/app/models/dataFormat-model';
import { EmailAccount, EmailFolder } from 'src/app/models/emailAccount-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { EmailAccountService } from 'src/app/services/email-account.service';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { ErrorHandler } from 'src/app/util/error-handler';
import { HateaosUtil } from 'src/app/util/hateaos-util';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent implements OnInit {

  account: EmailAccount;
  emailFolder: EmailFolder;
  selectedFormat: string;
  currentFormat: string;
  @ViewChild(ErrorMessageComponent)
  private errorComponent: ErrorMessageComponent;
  dataFormats: DataFormat[];

  constructor(public dialogRef: MatDialogRef<SaveDialogComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private service: EmailAccountService,
    private dataFormatService: DataFormatService,
    private _snackBar: MatSnackBar
  ) {
    //Object.assign does not crate a new instance of searchTearms
    //this.emailFolder = Object.assign({},data.folder));
    this.emailFolder = JSON.parse(JSON.stringify(data.folder));
    /* Cancelliamo la proprietà format dall'oggetto folder per evitare che spring data rest dia problemi al PUT */
    delete this.emailFolder.format;
    this.account = data.account;
  }

  ngOnInit(): void {

    if (this.emailFolder._links) {
      this.dataFormatService.getFormats().pipe(
        switchMap(response => {
          this.dataFormats = response._embedded.dataFormats;
          let formatUrl = HateaosUtil.normalizeTemplatedUrl(this.emailFolder._links.format);
          return this.service.get<DataFormat>(formatUrl);
        }),
      ).subscribe(response => {
        this.selectedFormat = response._links.self.href;
        this.currentFormat = this.selectedFormat;
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
    } else {
      this.dataFormatService.getFormats().subscribe(response => {
        this.dataFormats = response._embedded.dataFormats;
      })
    }

   
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.emailFolder._links) {

      let pipeObservable = this.service.updateObject<EmailFolder>(this.emailFolder._links.self.href, this.emailFolder);
      /* Se è stato cambiato il domain di riferimento effettuiamo anche la chiamata per associarlo */
      if (this.currentFormat != this.selectedFormat) {
        pipeObservable = pipeObservable.pipe(
          switchMap(response => {
            let formatHref = HateaosUtil.normalizeTemplatedUrl(response._links.format);
            return this.service.linkObject(formatHref, this.selectedFormat);
          })
        );
      }

      pipeObservable.subscribe(response => {
        this.dialogRef.close({ folder: this.emailFolder });
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });

    } else {

      let formatHref;
      let pipeObservable = this.service.createFolders(this.emailFolder).pipe(
        switchMap(response => {
          formatHref = HateaosUtil.normalizeTemplatedUrl(response._links.format);
          return this.service.linkObject(response._links.account.href, this.account._links.self.href)
        }),
        switchMap(response => {
          return this.service.linkObject(formatHref, this.selectedFormat)
        })
        
      ).subscribe(response => {
        this.dialogRef.close({ folder: this.emailFolder });
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });

    }
  }

  getErrorMessage(field: any){
    if(field != undefined){
      if(field.hasError('required')){
        return 'Required field' 
      }
    }
  }
}
