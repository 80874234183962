
<mat-drawer-container class="drawer-container" autosize [hasBackdrop]="true">
  <mat-drawer #drawer class="drawer-sidenav" mode="over">
    <div class="action-row">
      <span class="drawer-spacer"></span> 
      <button mat-icon-button class="close-drawer">
        <mat-icon (click)="drawer.toggle()">close</mat-icon>
      </button>
    </div>
    <mat-nav-list>
        <span  *ngFor="let sideLink of sideLinks" >
         <a mat-list-item (click)="drawer.close()" [routerLink]="sideLink.link" [routerLinkActive]="['active']"  *ngIf="hasRole(sideLink.role)"
          >
          <mat-icon>{{sideLink.icon}}</mat-icon> {{ sideLink.label }}</a>
        </span>
    </mat-nav-list>
   
  </mat-drawer>

  <div class="drawer-content">
    <mat-toolbar  *ngIf="isAuthenticated()">
      <mat-toolbar-row class="responsive-enel">
        <button mat-icon-button class="menu-button"  *ngIf="hasRole('ADMIN')">
          <mat-icon (click)="drawer.toggle()">menu</mat-icon>
        </button>
        <span class="nav-spacer only-mobile"></span> 
        <img class="logo" [routerLink]="'/home'" src="assets/logo.svg">
        <span class="nav-spacer"></span> 
        <div class="nav-menu">
          <a *ngFor="let navLink of navLinks" [routerLink]="navLink.link"  [routerLinkActive]="['active']">{{navLink.label}}</a>
        </div>
        <span class="nav-spacer no-mobile"></span> 
        <span class="username">{{user?.name}}</span>
        <button mat-icon-button (click)="doLogout()" matTooltip="Logout">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </div>
 

</mat-drawer-container>
