<div class="responsive-enel login-component">
    <form #loginForm="ngForm" (ngSubmit)="save()" autocomplete="off">
    <mat-card class="mat-elevation-z2">
        <p>
          <img [routerLink]="'/home'" src="assets/logo.svg">
        </p>
        
          <mat-form-field appearance="outline" class="full-width-field">
            <mat-label>Username</mat-label>
            <input matInput placeholder="Inserisci l'username" [(ngModel)]="username" name="username" required>
          </mat-form-field>
                  <mat-form-field appearance="outline" class="full-width-field">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Inserisci la password" [(ngModel)]="password" name="password" required>
          </mat-form-field>
        
       
    </mat-card>
    <app-error-message></app-error-message>
    <button mat-flat-button color="primary" type="submit" [disabled]="!loginForm.form.valid">Login</button>
    </form>
  </div>