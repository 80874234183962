import { Optional } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataFormat, DataTransformer } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { AppConstants } from 'src/app/shared/app-constants';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { HateaosUtil } from 'src/app/util/hateaos-util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandler } from 'src/app/util/error-handler';


@Component({
  selector: 'app-save-transformer',
  templateUrl: './save-transformer.component.html',
  styleUrls: ['./save-transformer.component.scss']
})
export class SaveTransformerComponent implements OnInit {
  private errorComponent: ErrorMessageComponent;

  dataFormat: DataFormat;
  transformer: DataTransformer = new DataTransformer();
  transformerTypes = AppConstants.trasformerTypes;

  field = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private service: DataFormatService,
    public dialogRef: MatDialogRef<SaveTransformerComponent>,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,

  ) {

    this.transformer = Object.assign({}, data.transformer);
    this.dataFormat = Object.assign({}, data.dataFormat);
  }
  



  getErrorMessage(field: any) {
    if (field.hasError('required')) {
      return 'Required field';
    }
  }
  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close();
  }
  save() {
    //console.log("salvato")

    if (this.transformer._links) {
      
      this.service.updateObject(this.transformer._links.self.href, this.transformer).subscribe(response => {
        this._snackBar.open('Elemento Modificato con successo');
        this.dialogRef.close({ transformer: this.transformer });
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
    } else {
      this.service.save(this.transformer).subscribe(response => {

        let urlFiglio =HateaosUtil.normalizeTemplatedUrl(response._links.format);
        let urlPadre= this.dataFormat._links.self.href;
        this.service.linkObject(urlFiglio, urlPadre).subscribe(response => {
          this.dialogRef.close({ transformer: this.transformer });
          this._snackBar.open('Elemento Creato con successo');
        })
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
    }
  }


}
