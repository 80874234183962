import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataFormat } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableListComponent } from 'src/app/shared/table-list/table-list.component';
import { AppConstants } from 'src/app/shared/app-constants';


@Component({
  selector: 'app-data-format',
  templateUrl: './data-format.component.html',
  styleUrls: ['./data-format.component.scss']
})
export class DataFormatComponent extends TableListComponent<DataFormat> {

  addRouteLink:string;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input('url') url : string;

  displayedColumns = ['name', 'description', 'type', 'separator', 'targetFormat','actions']; // 'stopEmpty', 'stopWord', 'stopColumn', 'skipCells',
  
  constructor(
    private service: DataFormatService,
    private router: Router,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private _snackBar: MatSnackBar,

  ) { 
    super(dialog);
    this.addRouteLink = AppConstants.Paths.DATA_FORMAT_SAVE;
  }


  loadData() {
    this.service.getFormats(this.pageSize, this.pageIndex).subscribe(result => {
      this.dataSource = new MatTableDataSource(result._embedded.dataFormats);
      this.paginator.length = result.page.totalElements;
      this.paginator.pageSize = result.page.size;
    });
  }

  edit(element:DataFormat) {
    this.router.navigate([AppConstants.Paths.DATA_FORMAT_SAVE], { relativeTo: this.route, queryParams:{ url: element._links.self.href }});
  }

  delete(element:DataFormat) {
    {
      this.service.deleteObject(element._links.self.href).subscribe(result => {
        this._snackBar.open('Elemento eliminato con successo');
        this.loadData();
      })
    }
  }

}
