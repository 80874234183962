import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetList } from 'src/app/interface/generic-links';
import { DataFormat, StandardCells, StandardCellsGetList } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { AppConstants } from 'src/app/shared/app-constants';
import { AbstractCellContainer } from 'src/app/shared/cell-container/cell-container.component';
import { HateaosUtil } from 'src/app/util/hateaos-util';

@Component({
  selector: 'app-cell-container',
  templateUrl: './cell-container.component.html',
  styleUrls: ['./cell-container.component.scss']
})
export class CellContainerComponent extends AbstractCellContainer<StandardCells> implements OnInit {


  constructor(protected service: DataFormatService,private _snackBar: MatSnackBar,
    ) {
    super(service);
  }

  ngOnInit(): void {
  }

  loadData(dataFormat: DataFormat) {
    let getcellsUrl = HateaosUtil.normalizeTemplatedUrl(dataFormat._links.standardCells) + "?projection=inlineStCellTransformer";
    this.service.get<GetList<StandardCellsGetList>>(getcellsUrl).subscribe(response => {
      this.totalElements = response._embedded.standardCells;
      this.maxPage = this.calcMaxPages();
      this.pickPageElements();
    }, error => {

    });
  }
  save() {
    this.service.saveCells(this.totalElements, this.dataFormat.id).subscribe(response => {
      this.loadData(this.dataFormat);
      this._snackBar.open('Operazione completata con successo');
    }, error => {
    })
  }

  add() {
    let addedCell = new StandardCells();
    addedCell.type = AppConstants.CELL_TYPE_NORMAL;
    addedCell.justAdded = true;
    this.totalElements.push(addedCell);
    this.maxPage = this.calcMaxPages();
    this.currentPage = this.maxPage;
    this.pickPageElements();
  }
}
