import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UploadComponent } from './components/upload/upload.component';
import { HomeComponent } from './components/home/home.component';
import { FormInfluencerComponent } from './components/form-influencer/form-influencer.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { FormsModule } from '@angular/forms';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AuthInterceptor } from './services/interceptor/auth.interceptor';
import { EmailAccountComponent } from './components/email-account/save-component/email-account.component';
import { EmailAccountListComponent } from './components/email-account/list-component/email-account-list.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { EmailFolderComponent } from './components/email-folder/email-folder.component';
import { SaveDialogComponent } from './components/email-folder/save-dialog/save-dialog.component';
import { ErrorMessageComponent } from './shared/error-message/error-message.component';
import { ImportFileComponent } from './components/data-format/wizard-rep-component/import-file.component';
import { DataFormatComponent } from './components/data-format/list-component/data-format.component';
import { SaveDataFormatComponent } from './components/data-format/save-component/save-data-format.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { RepcellComponent } from './components/data-format/repcell-component/repcell.component';
import { TabsDataformatComponent } from './components/data-format/tabs-dataformat/tabs-dataformat.component';
import { RepcellContainerComponent } from './components/data-format/repcell-container/repcell-container.component';
import { TabsEmailComponent } from './components/email-account/tabs-email/tabs-email.component';
import { TransformersListComponent } from './components/data-format/transformers-list/transformers-list.component';
import { SaveTransformerComponent } from './components/data-format/transformers-list/save-transformer/save-transformer.component';
import { CellContainerComponent } from './components/data-format/cell-container/cell-container.component';
import { CellItemComponent } from './components/data-format/cell-item/cell-item.component';
import { SamlLoginComponent } from './components/saml-login/saml-login.component';
import { NoCredentialsComponent } from './shared/no-credentials/no-credentials.component';

@NgModule({
  declarations: [
    AppComponent,
    UploadComponent,
    HomeComponent,
    FormInfluencerComponent,
    PageNotFoundComponent,
    LoginPageComponent,
    EmailAccountComponent,
    EmailAccountListComponent,
    DialogComponent,
    ErrorMessageComponent,
    EmailFolderComponent,
    SaveDialogComponent,
    DataFormatComponent,
    SaveDataFormatComponent,
    ImportFileComponent,
    BreadcrumbComponent,
    RepcellComponent,
    TabsDataformatComponent,
    RepcellContainerComponent,
    TabsEmailComponent,
    TransformersListComponent,
    SaveTransformerComponent,
    CellContainerComponent,
    CellItemComponent,
    SamlLoginComponent,
    NoCredentialsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    HttpClientModule,
    ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
