import { Component, OnInit, ViewChild } from '@angular/core';
import { InfluencerModel, InfluencerSource } from 'src/app/models/influencer-model';
import { InfluencerService } from 'src/app/services/influencer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandler } from 'src/app/util/error-handler';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { AppConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-form',
  templateUrl: './form-influencer.component.html',
  styleUrls: ['./form-influencer.component.scss']
})
export class FormInfluencerComponent implements OnInit {
  inputReadonly = true;
  numberPattern=AppConstants.numericFieldPattern
  datePattern= AppConstants.datePattern;
  form: InfluencerModel;
  sources: InfluencerSource[];

  @ViewChild(ErrorMessageComponent)
  private errorComponent: ErrorMessageComponent;

  constructor(private service: InfluencerService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.form = new InfluencerModel();

    this.service.getSources().subscribe(response => {
      this.sources = response['_embedded'].influencerSources;
    }, error => {
      this.errorComponent.showError(ErrorHandler.getError(error));
    });
  }
    getErrorMessage(field:any) {
      if (field.hasError('required')) {
        return 'Required field';
      } else if (field.hasError('pattern')){
        switch (field.name){
          case 'date': { return 'Please enter a correct date'}
          case 'comments' : { return 'Please enter only number'}
          case 'retweets' : { return 'Please enter only number'}
          case 'likes' : { return 'Please enter only number'}
        }
      }
    }
  save() {
    this.service.save(this.form).subscribe(response => {
      this._snackBar.open('Salvato con successo');
    }, error => {
      this.errorComponent.showError(ErrorHandler.getError(error));
    });
  }

}
