import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DataFormat, DataFormatGetList, DataTransformer, DataTransformerGetList, RepeatableCells, StandardCells } from '../models/dataFormat-model';
import { HateoasService } from './hateoas.service';
import { GetList } from '../interface/generic-links';
import { ImportFile } from '../models/importFile-model';

@Injectable({
  providedIn: 'root'
})
export class DataFormatService extends HateoasService {

  constructor(http: HttpClient) {
    super(http);
   }

  getFormats(size?: number, page?: number){

    let url = environment.apiUrl+"/dataFormats?projection=inlineDomainFormat";
    if(size && page) {
      url += "&size=" + size+"&page=" + page;
    } else {
      url += "&size=100&page=0";
    }

    return this.get<GetList<DataFormatGetList>>(url);
  }
  
  createDataFormat(dataFormat: DataFormat) {
    return this.http.post<DataFormat>(environment.apiUrl + "/dataFormats", dataFormat)
  }

  wizardRepCells(dataFormatId: string, fileToUpload, data:ImportFile) {

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append("type", data.type);
    formData.append("headerIdx", data.headerIndex+'');
    formData.append("separator", data.separator);

    return this.http.post<RepeatableCells[]>(environment.apiUrl + "/format/"+dataFormatId+"/getRepCells", formData)
  }

  saveRepCells(cells: RepeatableCells[], dataFormatId: number) {
    return this.http.post(environment.apiUrl + "/format/"+dataFormatId+"/saveRepCells", cells);
  }

  saveCells(cells: StandardCells[], dataFormatId: number) {
    return this.http.post(environment.apiUrl + "/format/"+dataFormatId+"/saveCells", cells);
  }

  getTransformers(url:string) {
    return this.http.get<GetList<DataTransformerGetList>>(url);
  }

  save(transformer: DataTransformer) {
    return this.createObject(environment.apiUrl + "/dataTransformers", transformer);
  }

  getDomainAttributes(dataFormatId: string) {
    return this.http.get<any>(environment.apiUrl + "/format/"+dataFormatId+"/domainAttributes");
  }

  getWithTargetId(targetFormatId: number) {
    return this.http.get<GetList<DataFormatGetList>>(environment.apiUrl + "/dataFormats/search/withTargetId?targetId="+targetFormatId);
  }

}

