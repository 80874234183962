<!--<p>home works!</p> -->
<div class="responsive-enel home-component">

         <mat-card  [routerLink]="'/upload'">
            <span><mat-icon >note_add</mat-icon></span>
            <span>Upload a file</span>
        </mat-card>

        <mat-card  [routerLink]="'/influencer'">
            <span><mat-icon >sentiment_very_satisfied</mat-icon></span>
            <span>Influencer</span>
        </mat-card> 
    
</div>