
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'src/app/shared/app-constants';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService, public router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    if(this.authService.isAuthenticated()) {
        request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.authService.getToken()}`
            }
          });
         
    }
    
    
  //return next.handle(request);

  return next.handle(request).pipe(catchError(err => {
    //console.log(err);
    if (err.status === 401) { //|| err.status === 403
        // auto logout if 401 response returned from api
        if(this.authService.isAuthenticated()) {
          this.authService.doPostLogout();
          this.router.navigate(['/'+AppConstants.Paths.LOGIN],{ queryParams: { logout: true }});
          //location.reload(true);
        }
        
    }
    
    //const error = err.error|| err.statusText;
    return throwError(err);
}));

  }
}