import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { EmailAccount } from 'src/app/models/emailAccount-model';
import { EmailAccountService } from 'src/app/services/email-account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { ErrorHandler } from 'src/app/util/error-handler';
import { AppConstants } from 'src/app/shared/app-constants';


@Component({
  selector: 'app-email-account',
  templateUrl: './email-account.component.html',
  styleUrls: ['./email-account.component.scss']
})
export class EmailAccountComponent implements OnInit {

  numberPattern=AppConstants.numericFieldPattern
  userPattern=AppConstants.emailPattern;
  pwCtrlPattern= AppConstants.passwordPattern;
  emailAccount: EmailAccount = new EmailAccount();
  @ViewChild(ErrorMessageComponent)
  private errorComponent: ErrorMessageComponent;
  fieldTextType: boolean = false;
  @Output() onAccountLoaded = new EventEmitter<EmailAccount>();

  constructor(
    private service: EmailAccountService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      let url = queryParams.get('url');
      if (url) {
        this.getAccount(url);
      }
    }
    );
  }

  togglePasswordVisible() {
    this.fieldTextType = !this.fieldTextType;
  }

  save() {
    //console.log("salvato")

    if (this.emailAccount._links) {
      this.service.updateObject(this.emailAccount._links.self.href, this.emailAccount).subscribe(response => {
        this._snackBar.open('Account Mail Modificato con successo');
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
    } else {
      this.service.save(this.emailAccount).subscribe(response => {
        this._snackBar.open('Account Mail Salvato con successo');
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
    }
  }

  getErrorMessage(field: any) {
    if(field != undefined){
      if (field.hasError('required')){
        return 'Required field'
      } else if(field.hasError('pattern')){
        switch (field.name){
          case 'password': { return 'Enter 4 character min - 20 character max'}
          case 'username' : { return 'Please enter a valid email address Es. enel@test.it'}
          case 'port' : { return 'Please enter only number'}

        }
      }
    }
  }

  getAccount(url: string) {
    if (url) {
      this.service.getAccount(url).subscribe(response => {
        this.emailAccount = response;
        this.onAccountLoaded.emit(this.emailAccount);
      })
    }
  }

  activate(account: EmailAccount) {
    this.service.activateAccount(account).subscribe(response => {
      this.emailAccount.enabled = response.enabled;
    }, error => {
      this.errorComponent.showError(ErrorHandler.getError(error));
    });
  }

  deactivate(account: EmailAccount) {
    this.service.deactivateAccount(account).subscribe(response => {
      this.emailAccount.enabled = response.enabled;
    }, error => {
      this.errorComponent.showError(ErrorHandler.getError(error));
    });

  }
}
