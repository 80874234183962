import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ErrorHandler } from 'src/app/util/error-handler';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { AppConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  password:string;
  username:string;
  responseError:string;
  @ViewChild(ErrorMessageComponent)
  private errorComponent: ErrorMessageComponent;
  
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  save() {
    this.authService.login(this.username, this.password).subscribe(response => {
      this.authService.doPostLogin(response);
      this.router.navigateByUrl('/'+AppConstants.Paths.HOME);
    }, error => {
        switch (error.status) {
            case 401:      //login
            case 403:     //forbidden
              this.errorComponent.showError("Credenziali errate");
              
              break;
            default:
              this.errorComponent.showError("Impossibile autenticarsi");
        }
        
    })
  }

}
