import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataFormat, DataTransformer } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { TableListLazyComponent } from 'src/app/shared/table-list/table-list-lazy.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HateaosUtil } from 'src/app/util/hateaos-util';
import { SaveDataFormatComponent } from '../save-component/save-data-format.component';
import { SaveTransformerComponent } from './save-transformer/save-transformer.component';

@Component({
  selector: 'app-transformers-list',
  templateUrl: './transformers-list.component.html',
  styleUrls: ['./transformers-list.component.scss']
})
export class TransformersListComponent extends TableListLazyComponent<DataTransformer> {

  @Input("format")
  dataFormat: DataFormat;

  displayedColumns = ['name','type', 'datePattern','separator', 'replace', 'replaceWith', 'actions'];

  @Output() onTransformerUpdated = new EventEmitter<DataTransformer[]>();

  constructor(private service: DataFormatService, protected dialog: MatDialog, private _snackBar: MatSnackBar,
  ) { super(dialog) }


  addTransformer() {
    let transformer = new DataTransformer();
    this.openTransformerDialog(transformer);
  }

  edit(transformer: DataTransformer) {
    this.openTransformerDialog(transformer)
  }

  openTransformerDialog(transformer: DataTransformer) {
    const dialogRef = this.dialog.open(SaveTransformerComponent, {
      data: { transformer: transformer, dataFormat: this.dataFormat }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.transformer) {
        this._snackBar.open('Elemento salvato con successo');
        this.loadData();
      }
    });
  }

  loadDataByFormat(dataFormat: DataFormat) {
    this.service.getTransformers(dataFormat._links.dataTransformers.href).subscribe(result => {
      this.dataSource = new MatTableDataSource(result._embedded.dataTransformers);
      this.onTransformerUpdated.emit(result._embedded.dataTransformers);
    });
  }

  delete(transformer: DataTransformer) {
    this.service.deleteObject(transformer._links.self.href).subscribe(result => {
      this._snackBar.open('Elemento eliminato con successo');
      this.loadData();
    })
  }

  loadData() {
    let url = HateaosUtil.normalizeTemplatedUrl(this.dataFormat._links.dataTransformers);
    this.service.getTransformers(url).subscribe(result => {
      this.dataSource = new MatTableDataSource(result._embedded.dataTransformers);
      this.onTransformerUpdated.emit(result._embedded.dataTransformers);
    });
  }

}
