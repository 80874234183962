import { Component, OnInit } from '@angular/core';
import { EmailAccount } from 'src/app/models/emailAccount-model';

@Component({
  selector: 'app-tabs-email',
  templateUrl: './tabs-email.component.html',
  styleUrls: ['./tabs-email.component.scss']
})
export class TabsEmailComponent implements OnInit {

  emailAccount: EmailAccount = new EmailAccount();

  constructor() { }

  ngOnInit(): void {
  }

  onAccountLoaded(emailAccount: EmailAccount) {
    this.emailAccount = emailAccount;
    //this.repCellsComponent.loadData(emailAccount);
  }

}
