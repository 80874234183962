import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadService } from 'src/app/services/upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataFormat } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { ErrorHandler } from 'src/app/util/error-handler';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { DomainFormatService } from 'src/app/services/domain-format.service ';
import { DomainFormat } from 'src/app/models/domainFormat-model';
import { HateaosUtil } from 'src/app/util/hateaos-util';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  fileName: string;
  file:any;
  formats: DataFormat[];
  domainFormats: DomainFormat[];

  selectedTarget: DomainFormat;
  selectedFormat: DataFormat;
  responseError:string;
  
  @ViewChild(ErrorMessageComponent)
  private errorComponent: ErrorMessageComponent;

  constructor(private service: UploadService, private _snackBar: MatSnackBar,
    private formatService: DataFormatService, private domainService: DomainFormatService) { }

  ngOnInit(): void {

    this.domainService.getDomainFormats().subscribe(response => {
        this.domainFormats = response._embedded.domainFormats;
    }, error => {
      console.error("error", error);
    });
  }

  onDomainSelected() {
    
    this.formatService.getWithTargetId(this.selectedTarget.id).subscribe(response => {
        this.formats = response._embedded.dataFormats;
        if(this.formats.length == 1) {
          this.selectedFormat = this.formats[0];
        }
    }, error => {
      console.error("error", error);
    });
  }

  fileInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    if(this.file != undefined) {
      this.fileName = this.file.name;
    }
  }

  uploadFile() {
      this.service.uploadFile(this.file, this.selectedFormat.id)
      .subscribe(response => {
        this._snackBar.open('Inviato con successo');
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
  }

}
