import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit{

  message: string;
  currentTimeout:any;

  constructor() { }

  showError(error) {
    this.message = error;
    
    if(this.currentTimeout != undefined) {
      clearTimeout(this.currentTimeout);
    }
    this.currentTimeout = setTimeout(()=> {
      this.message = undefined;
    },2000);
  }

  ngOnInit(): void {
    
  }

}
