import { HrefLink,SelfLink } from '../interface/generic-links';

export class HateaosUtil {
    public static parseId(link:SelfLink):string {
        let url = this.normalizeTemplatedUrl(link.self);
        let params:string[] = url.split("/");
        return params[params.length-1];
    }

    public static normalizeTemplatedUrl(link:HrefLink): string {
        if(link.templated) { 
            let url  = link.href; 
            return url.substring(0, url.indexOf("{"));
        }
            
        return link.href;
    }
}