import { Component, Input } from '@angular/core';
import { DataFormat, DataTransformer } from 'src/app/models/dataFormat-model';
import { HateoasService } from 'src/app/services/hateoas.service';

@Component({
  selector: 'abs-cell-container',
  template: ``
})
export abstract class AbstractCellContainer<T> {

  totalElements = [];
  pageElements = [];
  perPage: number = 5;
  currentPage: number = 0;
  maxPage: number;

  @Input("format")
  dataFormat: DataFormat;

  @Input("transformers")
  dataTransformers: DataTransformer;

  constructor(protected service: HateoasService) { }

  abstract loadData(dataFormat: DataFormat);

  abstract save();

  loadDataSource(elements: T[]) {
    this.totalElements = elements;
    this.currentPage = 0;
    this.maxPage = this.calcMaxPages();
    this.pickPageElements();
  }

  pickPageElements() {
    let startIdx = this.currentPage + (this.currentPage * (this.perPage - 1));
    let endIdx = startIdx + this.perPage;
    if (endIdx > this.totalElements.length) {
      endIdx = this.totalElements.length;
    }
    this.pageElements = this.totalElements.slice(startIdx, endIdx);
  }

  nextPage() {
    this.currentPage++;
    if (this.currentPage > this.maxPage) {
      this.currentPage = this.maxPage;
    }
    this.pickPageElements();
  }

  prevPage() {
    this.currentPage--;
    if (this.currentPage < 0) {
      this.currentPage = 0;
    }
    this.pickPageElements();
  }

  onDeleteCell(data: any) {
    let cell: T = data.cell;
    //let index = data.index;
    //this.pageElements.splice(index, 1);
    let totalIndex = data.index + (this.currentPage * this.perPage);
    this.totalElements.splice(totalIndex, 1);
    this.maxPage = this.calcMaxPages();
    if (this.currentPage > this.maxPage) {
      this.currentPage = this.maxPage;
    }
    this.pickPageElements();
  }

  calcMaxPages() {
    if (this.totalElements.length % this.perPage != 0) {
      return Math.floor(this.totalElements.length / this.perPage);
    }
    return (this.totalElements.length / this.perPage) - 1;
  }

}