import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HateoasService {

  constructor(public http: HttpClient) { }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }
  createObject<T>(url: string, body: T): Observable<T> {
    return this.http.post<T>(url, body);
  }
  updateObject<T>(url: string, body: T | any) {
    return this.http.put<T>(url, body);
  }
  patchObject<T>(url: string, body: T | any) {
    return this.http.patch<T>(url, body);
  }
  deleteObject(url: string): Observable<void> {
    return this.http.delete<void>(url);
  }
  /* url = self href associazione figlio, link = self href padre*/
  linkObject(url:string, link:string): Observable<any> {
    return this.http.put<any>(url, link, { headers: { 'Content-Type': 'text/uri-list' } });
  }

}