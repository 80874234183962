<div class="save-data-format-component">
    <form #socialForm="ngForm" (ngSubmit)="save()">
        <div class="grow-container">
            <mat-form-field appearance="outline" class="grow-width-field">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="dataFormat.name" name="name" #name=ngModel required>
                <mat-error *ngIf="name.invalid">{{getErrorMessage(name)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow-width-field grow-middle-field"
                >
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="dataFormat.type" name="type">
                    <mat-option *ngFor="let formatType of formatTypes" [value]="formatType">{{formatType}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Domain Format</mat-label>
                <mat-select [(ngModel)]="selectedDomain" name="domainFormatName">
                    <mat-option *ngFor="let domain of domains" [value]="domain._links.self.href">{{domain.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="grow-container">
  
            <mat-form-field appearance="outline" class="grow-width-field"
            [ngClass]="{'no-padding-field': dataFormat.type != 'CSV'}">
                <mat-label>Description</mat-label>
                <input matInput placeholder="Description" [(ngModel)]="dataFormat.description" name="description"
                    #description=ngModel required>
                <mat-error *ngIf="description.invalid">{{getErrorMessage(description)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" style="width: 100px" *ngIf="dataFormat.type == 'CSV'">
                <mat-label>Separator</mat-label>
                <input matInput placeholder="Separator" [(ngModel)]="dataFormat.separator" name="separator" required>
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline" *ngIf="dataFormat.type != 'CSV'">
            <mat-label>Righe da saltare</mat-label>
            <input matInput placeholder="Es. 10,11" 
            [(ngModel)]="dataFormat.skipCells" 
            name="skipCells" 
            [pattern]="skipPattern"
           
            #skipCells=ngModel>
            <mat-error *ngIf="skipCells.invalid">{{getErrorMessage(skipCells)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="dataFormat.type != 'CSV'">
            <mat-label>Stop Rules</mat-label>
            <mat-select [(ngModel)]="selectedStop" name="selectedStop" #stop=ngModel required>
                <mat-option *ngFor="let stopIfEmpty of stopIfEmpties" [value]="stopIfEmpty.id" >
                    {{stopIfEmpty.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="grow-container" *ngIf="dataFormat.type != 'CSV'">

            <mat-form-field appearance="outline" class="grow-width-field"
                [ngClass]="{'no-padding-field':selectedStop == 1 }" *ngIf="selectedStop == 1">
                <mat-label>Stop Word</mat-label>
                <input #test matInput placeholder="Stop Word" [(ngModel)]="dataFormat.stopConfig.stopWord"
                    name="stopWord" #stopWord=ngModel required>
                    <mat-error *ngIf="stopWord.invalid"> {{getErrorMessage(stopWord)}}</mat-error>
            </mat-form-field>


            <mat-form-field appearance="outline" class="grow-width-field grow-middle-field"
                [ngClass]="{'no-padding-field':selectedStop == 0 }"
                *ngIf="selectedStop == 1 || selectedStop == 0"
                >
                <mat-label>Stop Column</mat-label>
                <input matInput placeholder="Stop Column" [(ngModel)]="dataFormat.stopConfig.stopColumn"
                    name="stopColumn" 
                    #stopColumn=ngModel 
                    [pattern]="numberPattern"
                    required>
                    <mat-error *ngIf="stopColumn.invalid"> {{getErrorMessage(stopColumn)}}</mat-error>
            </mat-form-field>

        </div>

        <app-error-message></app-error-message>
        <div class="form-actions">
            <button mat-flat-button color="primary" type="submit" [disabled]="!socialForm.form.valid">Save</button>
        </div>
    </form>
</div>