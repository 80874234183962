<div class="grow-container">
    
    <mat-form-field appearance="outline" class="grow-width-field">
        <mat-label>Header name</mat-label>
        <input matInput placeholder="Header name" [(ngModel)]="cell.headerName" name="headerName[{{index}}]" #headerName=ngModel required>
        <mat-error *ngIf="headerName.invalid">{{getErrorMessage(headerName)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
        <mat-label>Target name</mat-label>
        <input type="text"
        [ngModel]="cell.targetName" (ngModelChange)="targetValueChange($event)"
        placeholder="Target name" matInput [matAutocomplete]="auto"
            name="targetName[{{index}}]" #targetName=ngModel required>
        <mat-error *ngIf="targetName.invalid">{{getErrorMessage(targetName)}}</mat-error>

        <mat-icon matSuffix *ngIf="isDomainField(cell.targetName)">playlist_add_check</mat-icon>
        <mat-icon matSuffix *ngIf="!isDomainField(cell.targetName)">playlist_add</mat-icon>
        
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="targetCompleteSelected($event)">
            <mat-option *ngFor="let option of filteredOptions" [value]="option" >
                {{option.fieldName}}
            </mat-option>
        </mat-autocomplete>

    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
        <mat-label>Target type</mat-label>
        <mat-select [(ngModel)]="cell.targetType" #targetType=ngModel name="targetType[{{index}}]" required>
            <mat-option *ngFor="let dataType of cellTargetTypes" [value]="dataType">{{dataType}}</mat-option>
        </mat-select>
        <mat-error *ngIf="targetType.invalid">{{getErrorMessage(targetType)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field" *ngIf="cell.headerRowIdx == undefined && type != 'CSV'">
        <mat-label>Header idx</mat-label>
        <input matInput placeholder="Header idx" [(ngModel)]="cell.headerRowIdx" name="headerRowIdx[{{index}}]" #headerRowIdx=ngModel required>
        <mat-error *ngIf="headerRowIdx.invalid">{{getErrorMessage(headerRowIdx)}}</mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
        <mat-label>Transformer</mat-label>
        <mat-select [(ngModel)]="selectedTransformer" name="transformer[{{index}}]" (selectionChange)="onSelectedTransformer($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let transformer of transformers" [value]="transformer.id">{{transformer.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="deleteCell()">
        <mat-icon>delete</mat-icon>
    </button>
</div> 