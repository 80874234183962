import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { AppConstants } from './shared/app-constants';
import { Account } from './models/account-model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mc-web';
  user: Account;

  sideLinks = [
    {
      label: "Home",
      link: "/"+AppConstants.Paths.HOME,
      icon: "home",
      role: "USER"
    },
  /*
    {
      label: "Upload",
      link: "/"+AppConstants.Paths.UPLOAD,
      icon: "note_add",
      role: "USER"
    },
  
    {
      label: "Influencer",
      link: "/"+AppConstants.Paths.INFLUENCER,
      icon: "sentiment_very_satisfied",
      role: "USER"
    },
    */
    {
      label:"Email boxes",
      link:"/"+AppConstants.Paths.ACCOUNT_LIST,
      icon: "email",
      role: "ADMIN"
    },
    {
      label:"Format configuration",
      link:"/"+AppConstants.Paths.DATA_FORMAT,
      icon: "list_alt",
      role: "ADMIN"
    }

  ]

  navLinks = [
    /*
    {
      label: "Upload",
      link: "/"+AppConstants.Paths.UPLOAD,
    }
    ,
    {
      label: "Influencer",
      link: "/"+AppConstants.Paths.INFLUENCER,
    },
    */
  ]

  constructor(private authService: AuthService, private router: Router) {

  }

  doLogout() {
    this.authService.logout().subscribe(() => {
      //this.authService.doPostLogout();
      //this.router.navigate(['/'+AppConstants.Paths.LOGIN],{ queryParams: { logout: true }});
    },error => {

    }, () => {
      this.authService.doPostLogout();
      this.router.navigate(['/'+AppConstants.Paths.LOGIN],{ queryParams: { logout: true }});
    });
   
  }

  isAuthenticated() {
    let authenticated:boolean = this.authService.isAuthenticated();
    if(authenticated && this.user == undefined) {
      this.user = this.authService.getUser();
    }
    return authenticated;
  }

  hasRole(role:string) {
    return this.authService.hasRole(role);
  }

}
