import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppConstants } from '../shared/app-constants';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isAuthenticated()) {
            if(this.authenticationService.hasRole(AppConstants.Security.ROLE_ADMIN)) {
                return true;
            } else {
                this.router.navigate(['/'+AppConstants.Paths.NO_CREDENTIAL]);
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/'+AppConstants.Paths.LOGIN], { queryParams: { returnUrl: state.url }});
        return false;
    }
}