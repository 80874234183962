<div class="responsive-enel tabs-dataformat-component">
    <app-breadcrumb></app-breadcrumb>
    <mat-card>
        <mat-tab-group dynamicHeight>
            <mat-tab label="Format">
                <div class="tab-padding">
                    <app-save-data-format (onFormatLoaded)="onFormatLoaded($event)"></app-save-data-format>
                </div>
            </mat-tab>
            <mat-tab label="Repeatable cells" [disabled]="!dataFormat?._links" >
                <app-repcell-container [format]="dataFormat" [transformers]="dataTransformers"></app-repcell-container>
            </mat-tab>
            <mat-tab label="Standard cells" [disabled]="!dataFormat?._links" >
                <app-cell-container [format]="dataFormat" [transformers]="dataTransformers"></app-cell-container>
            </mat-tab>
            <mat-tab label="Transformers" [disabled]="!dataFormat?._links" >
                <app-transformers-list [format]="dataFormat" (onTransformerUpdated)="onTransformerUpdated($event)"></app-transformers-list>
            </mat-tab>
        
        </mat-tab-group>
    </mat-card>
</div>