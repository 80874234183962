<div class="bg-container">
  <div class="responsive-enel form-component">
    <form #socialForm="ngForm" (ngSubmit)="save()" autocomplete="off">
      <mat-card class="mat-elevation-z2">

        <mat-form-field appearance="outline" class="full-width-field">
          <mat-label>Campaign Name</mat-label>
          <input matInput placeholder="Insert a campaign name" [(ngModel)]="form.campaign_name" name="campaign_name"
            #campaign_name= ngModel required>
            <mat-error *ngIf="campaign_name.invalid">{{getErrorMessage(campaign_name)}}</mat-error>
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-field">
          <mat-label>Screen name</mat-label>
          <input matInput placeholder="Insert screen name" [(ngModel)]="form.screen_name" name="screen_name"
            #screen_name= ngModel required>
            <mat-error *ngIf="screen_name.invalid">{{getErrorMessage(screen_name)}}</mat-error>
          <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-field">
          <mat-label>Username</mat-label>
          <input matInput placeholder="Insert username" [(ngModel)]="form.username" name="username" #username= ngModel required>
         <mat-error *ngIf="username.invalid"> {{getErrorMessage(username)}}</mat-error>
          <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-field">
          <mat-label>Source</mat-label>
          <mat-select [(ngModel)]="form.source.id" name="source" #source= ngModel required>
            <mat-option *ngFor="let source of sources" [value]="source.id">
              {{source.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="source.hasError">{{getErrorMessage(source)}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width-field">
          <mat-label>Url/Id post</mat-label>
          <input matInput placeholder="Post url" [(ngModel)]="form.url" name="url" #url_post=ngModel required>
         <mat-error *ngIf="url_post.invalid">{{getErrorMessage(url_post)}}</mat-error>
          <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
          <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>

        <mat-form-field color="accent" appearance="outline" class="full-width-field">
          <mat-label>Publish day</mat-label>
          <input 
          class="input-datapicker"
          matInput [matDatepicker]="picker1" 
          [(ngModel)]="form.publish_day"
          #day=ngModel 
          name="publish_day" 
          required    
          ng-readonly="true"
          [readonly]="inputReadonly"   
          
          >
          <mat-error *ngIf="day.invalid">{{getErrorMessage(day)}}</mat-error>
          <mat-datepicker-toggle class="datapicker" matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 ></mat-datepicker>
        </mat-form-field>
<!-- 
        <mat-form-field appearance="outline" class="full-width-field">
          <mat-label>Publish day</mat-label>
          <input matInput 
          placeholder="Date" 
          [(ngModel)]="form.publish_day" 
          name="date" 
          #date=ngModel 
          [pattern]="datePattern"
          required>
         <mat-error *ngIf="date.invalid">{{getErrorMessage(date)}}</mat-error>
        </mat-form-field> -->
       
        <div class="grow-container">
          <mat-form-field appearance="outline" class="grow-width-field">
            <mat-label>Nr. Comments</mat-label>
            <input matInput placeholder="Number of comments" 
            [(ngModel)]="form.comments" 
            name="comments" 
            #comments=ngModel 
            [pattern]="numberPattern"
            required>
             <mat-error *ngIf="comments.invalid">{{getErrorMessage(comments)}}</mat-error>
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>

          <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
            <mat-label>Nr. Share/Retweets</mat-label>
            <input matInput placeholder="Number of share/retweet" [(ngModel)]="form.retweets" 
              name="retweets"
              #retweets=ngModel 
              [pattern]="numberPattern" 
              required>
              <mat-error *ngIf="retweets.invalid">{{getErrorMessage(retweets)}}</mat-error>
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>

          <mat-form-field appearance="outline" class="grow-width-field">
            <mat-label>Nr. Likes</mat-label>
            <input matInput placeholder="Number of likes" [(ngModel)]="form.likes" name="likes" #likes=ngModel [pattern]="numberPattern" required>
            <mat-error *ngIf="likes.invalid">{{getErrorMessage(likes)}}</mat-error>
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
        </div>
        <app-error-message></app-error-message>
        <button mat-flat-button color="primary" type="submit" [disabled]="!socialForm.form.valid">Save</button>
      </mat-card>
    
    </form>
  </div>
</div>