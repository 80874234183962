<div class="responsive-enel login-component">
<div *ngIf="!logoutSuccess">

    <div *ngIf="showUnauthorizedMessage">
      Your are not authenticated, please wait a few seconds for redirection to saml identity provider...
    </div>

  </div>

  <div *ngIf="logoutSuccess">
    Successfully logout. <a (click)="tryLogin()">Click here</a> to log in again
  </div>
</div>