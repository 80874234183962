import { HrefLink } from '../interface/generic-links';
import { DomainFormat } from './domainFormat-model';
import { Hateoas } from './hateos';

export class DataFormat extends Hateoas<DataFormatLinks>{
    name: string;
    description: string;
    type: string;
    separator: string;
    skipCells:number;
    stopConfig= new StopConfig();

    /* only with projection and custom controllers */
    targetFormat?: DomainFormat;
    
}

export interface DataFormatLinks {
  dataTransformers: HrefLink;
  standardCells: HrefLink;
  repeatableCells: HrefLink;
  targetFormat: HrefLink;
}
export class StopConfig {
  stopIfEmpty?: boolean;
  stopWord?: string;
  stopColumn?: number;
}

export interface DataFormatGetList {
  dataFormats: DataFormat[];
}


export class AbstractCell<T> extends Hateoas<T> {
  targetName:string;
  targetType:string;

   /* only with projection and custom controllers */
   transformer?: DataTransformer; // = new DataTransformer();
   justAdded?:boolean;
}

export class RepeatableCells extends AbstractCell<RepeatableCellsLinks>{
  headerName: string;
  headerRowIdx?: number;
}

export class RepeatableCellsLinks {
  dataFormat: HrefLink;
  transformer:HrefLink;
}

export interface RepeatableCellsGetList {
  repeatableCells: RepeatableCells[];
}


export class StandardCells extends AbstractCell<StandardCellsLinks>{
  type:string;
	constValue:string;
	position:string;
}

export class StandardCellsLinks {
  dataFormat: HrefLink;
  transformer:HrefLink;
}

export interface StandardCellsGetList {
  standardCells: StandardCells[];
}


export class DataTransformer extends Hateoas<DataTransformerLinks>{
  name:string;
  type:string;
	
	/* Split date transformer */
	datePattern:string;
	separator:string;
	
	/* Replace transformer */
	replaceText:string;
	replaceWith:string;
}

export class DataTransformerLinks {
  format: HrefLink;
}

export interface DataTransformerGetList {
  dataTransformers: DataTransformer[];
}