import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data } from '@angular/router';
import { GetList } from 'src/app/interface/generic-links';
import { DataFormat, DataTransformer, RepeatableCells, RepeatableCellsGetList } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { AbstractCellContainer } from 'src/app/shared/cell-container/cell-container.component';
import { HateaosUtil } from 'src/app/util/hateaos-util';
import { ImportFileComponent } from '../wizard-rep-component/import-file.component';

@Component({
  selector: 'app-repcell-container',
  templateUrl: './repcell-container.component.html',
  styleUrls: ['./repcell-container.component.scss']
})
export class RepcellContainerComponent extends AbstractCellContainer<RepeatableCells> implements OnInit {

  startedWizard: boolean;
  domainAttributes:[];
  dataFormat: DataFormat;
  @ViewChild(ImportFileComponent) wizardComponent:ImportFileComponent;
  currentHeaderIdx:number;


  constructor(protected service: DataFormatService, private _snackBar: MatSnackBar) {
    super(service);
  }

  ngOnInit(): void {
  }

  loadData(dataFormat: DataFormat) {
    let dataFormatId = HateaosUtil.parseId(dataFormat._links);
    this.wizardComponent.initFormat(dataFormatId);
    
    let getRepCellUrl = HateaosUtil.normalizeTemplatedUrl(dataFormat._links.repeatableCells) + "?projection=InlineRepCellTransformer";
    this.service.get<GetList<RepeatableCellsGetList>>(getRepCellUrl).subscribe(response => {
      this.totalElements = response._embedded.repeatableCells;
      if(this.totalElements.length > 0) {
        this.currentHeaderIdx = this.totalElements[0].headerRowIdx;
      }
      this.currentPage = 0;
      this.maxPage = this.calcMaxPages();
      this.pickPageElements();
    }, error => {

    });

    this.service.getDomainAttributes(dataFormatId).subscribe(response => {
      this.domainAttributes = response;
    }, error => {

    });
  }

  save() {
    this.service.saveRepCells(this.totalElements, this.dataFormat.id).subscribe(response => {
      this.startedWizard = false;
      this.loadData(this.dataFormat);
      this._snackBar.open('Operazione completata con successo');
    }, error => {

    })
  }

  add() {
    let addedCell = new RepeatableCells();
    addedCell.justAdded = true;
    if(this.currentHeaderIdx != undefined) {
      addedCell.headerRowIdx = this.currentHeaderIdx;
    }
    this.totalElements.push(addedCell);
    this.maxPage = this.calcMaxPages();
    this.currentPage = this.maxPage;
    this.pickPageElements();
  }

  startWizard() {
    this.startedWizard = true;
    //this.router.navigate([AppConstants.Paths.WIZARD_FORMAT], {relativeTo: this.route})
  }

}
