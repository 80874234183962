import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { EmailAccount, EmailFolder } from 'src/app/models/emailAccount-model';
import { EmailAccountService } from 'src/app/services/email-account.service';
import { TableListComponent } from 'src/app/shared/table-list/table-list.component';
import { HateaosUtil } from 'src/app/util/hateaos-util';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';

@Component({
  selector: 'app-email-folder',
  templateUrl: './email-folder.component.html',
  styleUrls: ['./email-folder.component.scss']
})
export class EmailFolderComponent extends TableListComponent<EmailFolder> {

  @Input()
  private account: EmailAccount;

  displayedColumns = ['folder', 'from', 'subject', 'body','dataFormat', 'actions'];

  constructor(
    private service: EmailAccountService,
    private _snackBar: MatSnackBar,
    protected dialog: MatDialog
  ) {
    super(dialog);
  }

  loadData() {
    let url = HateaosUtil.normalizeTemplatedUrl(this.account._links.folders);
    this.service.getFolders(url).subscribe(result => {
      this.dataSource = new MatTableDataSource(result._embedded.emailFolders);
    });
  }

  addFolder() {
    let folder = new EmailFolder();
    this.openFolderDialog(folder);
  }

  edit(folder: EmailFolder) {
    this.openFolderDialog(folder);
  }

  openFolderDialog(folder: EmailFolder) {
    const dialogRef = this.dialog.open(SaveDialogComponent, {
      data: { folder: folder, account: this.account }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.folder) {
        this._snackBar.open('Cartella salvata con successo');
        this.loadData();
      }
    });
  }

  delete(emailFolder: EmailFolder) {
    this.service.deleteObject(emailFolder._links.self.href).subscribe(result => {
      this._snackBar.open('Cartella eliminata con successo');
      this.loadData();
    })
  }

}
