import { Component, OnInit, ViewChild } from '@angular/core';
import { DataFormat, DataTransformer } from 'src/app/models/dataFormat-model';
import { CellContainerComponent } from '../cell-container/cell-container.component';
import { RepcellContainerComponent } from '../repcell-container/repcell-container.component';
import { TransformersListComponent } from '../transformers-list/transformers-list.component';

@Component({
  selector: 'app-tabs-dataformat',
  templateUrl: './tabs-dataformat.component.html',
  styleUrls: ['./tabs-dataformat.component.scss']
})
export class TabsDataformatComponent implements OnInit {

  @ViewChild(RepcellContainerComponent) repCellsComponent:RepcellContainerComponent;
  @ViewChild(TransformersListComponent) transformersComponent:TransformersListComponent;
  @ViewChild(CellContainerComponent) cellComponent:CellContainerComponent;

  dataFormat: DataFormat;
  dataTransformers: DataTransformer[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  onFormatLoaded(format: DataFormat) {
    this.dataFormat = format;
    this.repCellsComponent.loadData(format);
    this.transformersComponent.loadDataByFormat(format);
    this.cellComponent.loadData(format);
  }

  onTransformerUpdated(transformers: DataTransformer[]) {
    this.dataTransformers = transformers;
  }

}
