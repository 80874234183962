import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataTransformer, RepeatableCells } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { AppConstants } from 'src/app/shared/app-constants';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-repcell',
  templateUrl: './repcell.component.html',
  styleUrls: ['./repcell.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class RepcellComponent implements OnInit {

  @Input("type")
  type:string;
  @Input("cell")
  cell:RepeatableCells;
  @Input("index")
  index:number;
  @Input("wizard")
  wizard:boolean = false;
  @Input("domainAttributes")
  domainAttributes:any[];

  filteredOptions: string[];

  @Output() onDelete = new EventEmitter<any>();

  cellTargetTypes = AppConstants.dataTypes;

  @Input("transformers")
  transformers: DataTransformer[] = [];

  selectedTransformer: number;

  constructor(private service: DataFormatService,
    protected dialog: MatDialog) { 
    }

  ngOnInit(): void {
    if(this.cell.transformer) {
      this.selectedTransformer = this.cell.transformer.id;
    }
  }

  deleteCell() {

      if(this.wizard || this.cell.justAdded) {
       this.sendDeleteEvent();
      } else {
        this.openDeleteDialog();    
      }
      
  }

  openDeleteDialog() {
    let action = 'Elimina';
    const dialogRef = this.dialog.open(DialogComponent, {
        data: { action: action, obj: this.cell }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'Elimina') {
           this.service.deleteObject(this.cell._links.self.href).subscribe(response => {
              this.sendDeleteEvent();
          }, error => {

          })
        }
    });
}

  sendDeleteEvent() {
    let data = {
      cell:this.cell,
      index:this.index
    };
    this.onDelete.emit(data);
  }

  onSelectedTransformer(event) {
    if(event.value) {
        if(!this.cell.transformer) {
          this.cell.transformer = new DataTransformer();
        }
        this.cell.transformer.id = event.value;
    } else {
      this.cell.transformer = null;
    }
  }

  targetValueChange(newValue) {
    let value = newValue;
    if(newValue.fieldName) {
      value = newValue.fieldName;
    }
    
    this.cell.targetName = value;
    this.filteredOptions = this._filter(value);
  }

  targetCompleteSelected(event) {
    let value = event.option.value;
    this.cell.targetType = value.type;
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    //return this.domainAttributes.filter(option => option.fieldName.toLowerCase().includes(filterValue));
    return this.domainAttributes.filter(option => option.fieldName.toLowerCase().startsWith(filterValue));
  }
  
  getErrorMessage(field){
    if(field != undefined){
      if (field.hasError('required')){
        return 'Required field'
      }
    }
  }

  isDomainField(fieldName: string) {

    if(this.domainAttributes == undefined || fieldName == undefined) return false;

    /* se è un campo con separatore relativo ad uno split date */
    if(fieldName.indexOf(";") > 0) {
      //verifichiamo l'esistenza di entrambi i campi nella lista degli attributi di dominio

      let fieldNames = fieldName.split(";");
      for(let i= 0; i < fieldNames.length; i++) {
          let tempFieldName = fieldNames[i];
          let fieldLower = tempFieldName.toLowerCase();
          let found = this.domainAttributes.filter(option => option.fieldName.toLowerCase() == fieldLower);
          if(found.length == 0) {
            return false;
          };
      }
      return true;
    } else {
    
      let fieldLower = fieldName.toLowerCase();
      let found = this.domainAttributes.filter(option => option.fieldName.toLowerCase() == fieldLower);
      return found.length > 0;
    }
    
  }

}
