export class ErrorHandler {
  public static getError(err: any): string {
    const error = err.error || err.statusText;
    if (error.exceptionMessage) {
      if (error.message != null) {
        return error.message + ". " + error.exceptionMessage;
      } else {
        return error.exceptionMessage;
      }

    } else if (error.message) {
      return error.message;
    } else {
      return "Impossibile effettuare la richiesta. Riprova più tardi";
    }
  }
}