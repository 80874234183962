<div class="save-transformer-component">
    <form #saveTransformer="ngForm" (ngSubmit)="save()">

        <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Insert name" [(ngModel)]="transformer.name" 
                #name=ngModel 
                name="name"
                required>
        </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="transformer.type"  name="type">
                <mat-option *ngFor="let transformerType of transformerTypes" [value]="transformerType">
                    {{transformerType}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="grow-container">
            <mat-form-field appearance="outline" class="grow-width-field" [ngClass]="{'no-padding-field':transformer.type === 'FORMAT_DATE' }"
                *ngIf="transformer.type === 'FORMAT_DATE' || transformer.type === 'SPLIT_DATE'">
                <mat-label>Date Pattern</mat-label>
                <input matInput placeholder="Date Pattern" [(ngModel)]="transformer.datePattern" 
                #datePattern=ngModel 
                name="datePattern"
                required>
                <mat-error *ngIf="datePattern.invalid">{{getErrorMessage(datePattern)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow-width-field" *ngIf="transformer.type === 'SPLIT_DATE'">
                <mat-label>Separator</mat-label>
                <input matInput placeholder="Separator" [(ngModel)]="transformer.separator" #separator name="separator">
                <mat-error *ngIf="separator.invalid">{{getErrorMessage(separator)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow-width-field" *ngIf="transformer.type === 'REPLACE'">
                <mat-label>Replace Text</mat-label>
                <input matInput placeholder="Replace Text" [(ngModel)]="transformer.replaceText" #replaceText=ngModel name="replaceText">
                <mat-error *ngIf="replaceText.invalid">{{getErrorMessage(replaceText)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow-width-field" *ngIf="transformer.type === 'REPLACE'">
                <mat-label>Replace With</mat-label>
                <input matInput placeholder="ReplaceWith" [(ngModel)]="transformer.replaceWith" #replaceWith=ngModel name="replaceWith">
                <mat-error *ngIf="replaceWith.invalid">{{getErrorMessage(replaceWith)}}</mat-error>
            </mat-form-field>
        </div>

        <app-error-message></app-error-message>
        <div class="dialog-actions">
            <button mat-stroked-button class="btnCancel"  (click)="cancel()" type="button" color="warn">Cancel</button>
            <button mat-flat-button color="primary" type="submit"
                [disabled]="!saveTransformer.form.valid">Save</button>
        </div>
    </form>

</div>