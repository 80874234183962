import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmailAccount, EmailFolder, EmailFolderGetList } from '../models/emailAccount-model';
import { Observable } from 'rxjs';
import { HateoasService } from './hateoas.service';
import { GetList } from '../interface/generic-links';
import { HateaosUtil } from '../util/hateaos-util';
import { DomainFormat, DomainFormatGetList } from '../models/domainFormat-model';

@Injectable({
  providedIn: 'root'
})
export class DomainFormatService extends HateoasService {

  constructor(http: HttpClient) {
    super(http);
   }

  // getDomainFormats(size: number, page: number) {
  //   return this.http.get<any>(environment.apriUrl + "/domainFormats?size=" + size + "&page=" + page);
  // }

  getDomainFormats() {
    return this.http.get<GetList<DomainFormatGetList>>(environment.apiUrl + "/domainFormats");
  }

  getDomainFormat(url: string){
    return this.get<DomainFormat>(url);
  }
}
