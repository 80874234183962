import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FileDetector } from 'protractor';
import { DataTransformer, StandardCells } from 'src/app/models/dataFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { AppConstants } from 'src/app/shared/app-constants';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';

@Component({
  selector: 'app-cell-item',
  templateUrl: './cell-item.component.html',
  styleUrls: ['./cell-item.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CellItemComponent implements OnInit {

  @Input("cell")
  cell: StandardCells;
  @Input("index")
  index: number;

  @Output() onDelete = new EventEmitter<any>();

  cellTypes = AppConstants.cellTypes;
  cellTargetTypes = AppConstants.dataTypes;

  @Input("transformers")
  transformers: DataTransformer[] = [];

  selectedTransformer: number;

  constructor(private service: DataFormatService,
    protected dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.cell.transformer) {
      this.selectedTransformer = this.cell.transformer.id;
    }
  }

  deleteCell() {

    if (this.cell.justAdded) {
      this.sendDeleteEvent();
    } else {
      this.openDeleteDialog();
    }

  }

  openDeleteDialog() {
    let action = 'Elimina';
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { action: action, obj: this.cell }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Elimina') {
        this.service.deleteObject(this.cell._links.self.href).subscribe(response => {
          this.sendDeleteEvent();
        }, error => {

        })
      }
    });
  }

  sendDeleteEvent() {
    let data = {
      cell: this.cell,
      index: this.index
    };
    this.onDelete.emit(data);
  }

  onSelectedTransformer(event) {
    if (event.value) {
      if (!this.cell.transformer) {
        this.cell.transformer = new DataTransformer();
      }
      this.cell.transformer.id = event.value;
    } else {
      this.cell.transformer = null;
    }
  }
  getErrorMessage(field: any) {
    if (field != undefined) {
      if (field.hasError('required')) {
        return 'Required field'
      }
    }
  }

  selectionChange(field) {
    if (this.cell.type == 'CONSTANT') {
      this.cell.position = undefined;
    }
    else if (this.cell.type == 'NORMAL') {
      this.cell.constValue = undefined;
    }
  }
}
