export class AppConstants  {

    public static CELL_TYPE_NORMAL = 'NORMAL';

    public static formatTypes = ['CSV','EXCEL','EXCEL2007'];

    public static dataTypes = ['TEXT','INTEGER','DOUBLE','BOOLEAN','LONG'];

    public static trasformerTypes = ['REPLACE','SPLIT_DATE','FORMAT_DATE'];

    public static cellTypes = ['CONSTANT',AppConstants.CELL_TYPE_NORMAL];

    public static emailPattern=/^[a-zA-Z0-9_+&*-]+(?:.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+.)+[a-zA-Z]{2,7}$/;

    public static passwordPattern=/^.{4,20}$/g;

    public static numericFieldPattern=/^[1-9]+[0-9]*$/;
    public static wordFieldPattern=/^[a-zA-Z]*$/;

    public static skipPattern=/^(((\d+)|(\d+-\d+))(,((\d+)|(\d+-\d+)))*)$/;

    public static datePattern = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/g; 

    static Security = class {
        public static ROLE_USER = 'USER';
        public static ROLE_ADMIN = 'ADMIN';
    }

    static Paths = class {
        public static HOME = 'home';
        public static LOGIN = 'saml';
        public static INFLUENCER = 'influencer';
        public static UPLOAD = 'upload';
        public static DATA_FORMAT = 'dataFormat';
        public static DATA_FORMAT_SAVE = 'save';
        public static ACCOUNT_LIST = 'email-account-list';
        public static ACCOUNT_SAVE = 'email-account';
        public static WIZARD_FORMAT = 'import-file';
        public static NO_CREDENTIAL= "no-credentials"; 
    }

}