<div class="responsive-enel data-format-component">
    <h1 class="component-title">Format list</h1>
    <button mat-flat-button color="primary" [routerLink]="addRouteLink">Add</button>
    <mat-card>
        <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="no-mobile"> Description </th>
                    <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.description}} </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="no-mobile"> Type </th>
                    <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.type}} </td>
                </ng-container>

                <ng-container matColumnDef="separator">
                    <th mat-header-cell *matHeaderCellDef class="no-mobile"> Separator </th>
                    <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.separator}} </td>
                </ng-container>

            <ng-container matColumnDef="stopEmpty">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Stop Empty </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.stopConfig?.stopIfEmpty}} </td>
            </ng-container> 

             <ng-container matColumnDef="stopWord">
                <th mat-header-cell *matHeaderCellDef class="no-mobile">  Stop Word </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.stopConfig?.stopWord}} </td>
            </ng-container>

            <ng-container matColumnDef="stopColumn">
                <th mat-header-cell *matHeaderCellDef class="no-mobile">  Stop Column </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.stopConfig?.stopColumn}} </td>
            </ng-container>

             <ng-container matColumnDef="skipCells">
                <th mat-header-cell *matHeaderCellDef class="no-mobile">  Skip Cells </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element?.skipCells}} </td>
            </ng-container> 

             <ng-container matColumnDef="targetFormat">
                <th mat-header-cell *matHeaderCellDef class="no-mobile">  Domain Format Name </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.targetFormat?.name}} </td> 
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-align-center"> Actions </th>
                <td mat-cell *matCellDef="let element" class="text-align-center">
                    <button mat-icon-button (click)="edit(element)" color="primary">
                        <mat-icon class="icons" aria-hidden="false" aria-label="Edit">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="openDeleteDialog(element)" [disabled]="element.enabled" color="primary">
                        <mat-icon class="icons" aria-hidden="false" aria-label="Cancel" >
                            delete</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="pageSizeOptions" (page)="pageEvent($event)">
        </mat-paginator>
    </mat-card>
</div>
