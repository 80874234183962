<div class="import-file-component">
    <form #uploadForm="ngForm" (ngSubmit)="uploadFile()">
        
            <p class="file-container">
                <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="fileInputChange($event)"
                    accept=".csv,.xls,.xlsx" />
                <mat-label>Filename: {{fileName}}</mat-label>
                <button type="button" mat-stroked-button color="accent" (click)="fileInput.click()">Choose a file</button>
            </p>
            <div class="grow-container">
                <mat-form-field appearance="outline" class="grow-width-field">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="importFile.type" name="type" (selectionChange)="onFormatSelected($event)">
                        <mat-option *ngFor="let formatType of formatTypes" [value]="formatType">{{formatType}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="field.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="grow-width-field grow-middle-field" *ngIf="importFile.type == 'CSV'">
                    <mat-label>Separator</mat-label>
                    <input matInput placeholder="Es. ," [(ngModel)]="importFile.separator" name="separator" required>
                    <mat-error *ngIf="field.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="grow-width-field">
                    <mat-label>Header Index</mat-label>
                    <input matInput placeholder="Header Index" [(ngModel)]="importFile.headerIndex" name="index" required min="1">
                    <mat-error *ngIf="field.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        <app-error-message></app-error-message>
        <div class="form-actions">
            <button class="submit" mat-flat-button color="primary" type="submit"
            [disabled]="!uploadForm.form.valid || file == undefined">Process</button>
        </div>
        
    </form>
</div>