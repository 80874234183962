<div class="responsive-enel email-account-list-component">
    <h1 class="component-title">Email boxes</h1>
    <button mat-flat-button color="primary" [routerLink]="addRouteLink">Add</button>
    <mat-card>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Name </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="enabled">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Enabled </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.enabled ? 'Si' : 'No'}} </td>
            </ng-container>

            <ng-container matColumnDef="host">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Host </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.config.host}} </td>
            </ng-container>

            <ng-container matColumnDef="port">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Port </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.config.port}} </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> Username </th>
                <td mat-cell *matCellDef="let element"> {{element.config.username}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-align-center"> Actions </th>
                <td mat-cell *matCellDef="let element" class="text-align-center">
                    <button mat-icon-button (click)="edit(element)" color="primary">
                        <mat-icon class="icons" aria-hidden="false" aria-label="Edit">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="openDeleteDialog(element)" [disabled]="element.enabled" color="primary">
                        <mat-icon class="icons" aria-hidden="false" aria-label="Delete" >
                            delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="pageSizeOptions" (page)="pageEvent($event)">
        </mat-paginator>
    </mat-card>
</div>