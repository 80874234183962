import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmailAccount, EmailAccountGetList, EmailFolder, EmailFolderGetList } from '../models/emailAccount-model';
import { HateoasService } from './hateoas.service';
import { GetList } from '../interface/generic-links';
import { HateaosUtil } from '../util/hateaos-util';

@Injectable({
  providedIn: 'root'
})
export class EmailAccountService extends HateoasService {

  constructor(http: HttpClient) {
    super(http);
   }

  save(emailAccount: EmailAccount) {
    return this.createObject(environment.apiUrl + "/emailAccounts", emailAccount);
  }

  getAccountLists(size: number, page: number) {
    return this.http.get<GetList<EmailAccountGetList>>(environment.apiUrl + "/emailAccounts?size=" + size + "&page=" + page);
  }

  // deleteAccount(id: number) {
  //   return this.http.delete(environment.apriUrl + "/emailAccounts/" + id);
  // }

  deleteAccount(url: string) {
    console.log("url" + url)
    return this.http.delete(url);
  }

  activateAccount(emailAccount: EmailAccount) {
    let id = HateaosUtil.parseId(emailAccount._links);
    return this.http.post<EmailAccount>(environment.apiUrl + "/recipient/"+id+"/activate",{});
    
  }

  deactivateAccount(emailAccount: EmailAccount) {
    let id = HateaosUtil.parseId(emailAccount._links);
    return this.http.post<EmailAccount>(environment.apiUrl + "/recipient/"+id+"/deactivate",{});
  }

  getAccount(url: string){
    return this.get<EmailAccount>(url);
  }

  getFolders(url: string){
    url += "?projection=inlineDataFormat";
    return this.get<GetList<EmailFolderGetList>>(url);
  }

  createFolders(emailFolder: EmailFolder) {
    return this.http.post<EmailFolder>(environment.apiUrl + "/emailFolders",emailFolder)
  }
}
