import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Account } from '../../models/account-model';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-saml-login',
  templateUrl: './saml-login.component.html',
  styleUrls: ['./saml-login.component.scss']
})
export class SamlLoginComponent implements OnInit {

  showUnauthorizedMessage: boolean;
  logoutSuccess: boolean = false;

  constructor(private httpClient: HttpClient, private authService: AuthService, private router: Router,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
        let logout = queryParams.get('logout');
        if (logout) {
          this.logoutSuccess = true;
        } else {
          this.tryLogin();
        }
      }
    );

  }

  tryLogin() {
    this.logoutSuccess = false;
    this.authService.samlLogin()
    .subscribe(
      response => {
        this.handleTokenSuccess(response as Account)
      }, error => {
        this.handleTokenError(error)
      });
  }

  handleTokenSuccess(apiToken: Account) {
    this.authService.doPostLogin(apiToken);
    this.router.navigateByUrl('/'+AppConstants.Paths.HOME);
  }

  handleTokenError(error : HttpErrorResponse) {
    if (error.status === 401) {
      this.showUnauthorizedMessage = true;
      setTimeout(() => window.location.replace(environment.baseUrl+'/saml/login'), 4000);
    }
  }

  /*
  logout() {
    this.authService.logout().subscribe(() => {
      this.authService.doPostLogout();
      this.logoutSuccess = true;
    });
  }
  */
}
