export class InfluencerModel {
   
    campaign_name:string; 
    screen_name:string; 
    username:string; 
    publish_day:Date; 
    comments:number;  
    retweets:number; 
    likes:number;
    url:string;
    source:InfluencerSource = new InfluencerSource();

}

export class InfluencerSource {
   
    id:number;
    name:string;

}