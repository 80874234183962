<form #folderDialogForm="ngForm" (ngSubmit)="save()">

    <mat-form-field appearance="outline" class="full-width-field">
        <mat-label>Folder name</mat-label>
        <input matInput placeholder="Inserisci un nome" [(ngModel)]="emailFolder.folder" name="folder" #folder=ngModel required>
        <mat-error *ngIf="folder.invalid">{{getErrorMessage(folder)}}</mat-error>
    </mat-form-field>

    <div class="grow-container">

        <mat-form-field appearance="outline" class="grow-width-field">
            <mat-label>From</mat-label>
            <input matInput placeholder="Inserisci la schermata" [(ngModel)]="emailFolder.searchTerms.fromTerm"
                name="fromTerm" >
        </mat-form-field>
        <mat-form-field appearance="outline" class="grow-width-field">
            <mat-label>Subject</mat-label>
            <input matInput placeholder="Inserisci nome utente" [(ngModel)]="emailFolder.searchTerms.subject"
                name="subject" >
        </mat-form-field>

        <mat-form-field appearance="outline" class="grow-width-field">
            <mat-label>Body</mat-label>
            <input matInput placeholder="Inserisci nome utente" [(ngModel)]="emailFolder.searchTerms.body" name="body"
                >
        </mat-form-field>

    </div>
    
    <mat-form-field appearance="outline" class="full-width-field">
        <mat-label>Format</mat-label>
        <mat-select [(ngModel)]="selectedFormat" name="format" #format=ngModel required>
            <mat-option *ngFor="let format of dataFormats" [value]="format._links.self.href">
                {{format.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="format.invalid"> {{getErrorMessage(format)}}</mat-error>
    </mat-form-field>

    <app-error-message></app-error-message>

    <div class="dialog-actions">
        <button mat-stroked-button (click)="cancel()" type="button" color="warn">Cancel</button>
        <button mat-flat-button color="primary" type="submit" [disabled]="!folderDialogForm.form.valid">Save</button>
    </div>
</form>