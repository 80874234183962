import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Data } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { DataFormat } from 'src/app/models/dataFormat-model';
import { DomainFormat } from 'src/app/models/domainFormat-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { DomainFormatService } from 'src/app/services/domain-format.service ';
import { AppConstants } from 'src/app/shared/app-constants';
import { ErrorMessageComponent } from 'src/app/shared/error-message/error-message.component';
import { ErrorHandler } from 'src/app/util/error-handler';

@Component({
  selector: 'app-save-data-format',
  templateUrl: './save-data-format.component.html',
  styleUrls: ['./save-data-format.component.scss'],
})
export class SaveDataFormatComponent implements OnInit {

  numberPattern=AppConstants.wordFieldPattern;
  skipPattern= AppConstants.skipPattern;
  dataFormat: DataFormat  = new DataFormat();
  domainFormat: DomainFormat;
  domains: DomainFormat[] = [];
  selectedStop: number;
  stopIfEmpties = [
    {
      id: 0,
      name: "Fermati se trovi la casella vuota"
    },
    {
      id: 1,
      name: "Fermati se trovi nella casella la parola"
    }];
  selectedDomain: string;
  currentDomain: string;

  editUrl: string;
  formatTypes = AppConstants.formatTypes;

  @Output() onFormatLoaded = new EventEmitter<DataFormat>();

  @ViewChild(ErrorMessageComponent)
  private errorComponent: ErrorMessageComponent;

  field = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    // public dialogRef: MatDialogRef<SaveDataFormatComponent>,
    private service: DataFormatService,
    private serviceDomain: DomainFormatService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
  }

  getErrorMessage(field:any) {
    if (field.hasError('required')) {
      return 'Required field';
    } else if (field.hasError('pattern')){
      switch (field.name){
        case 'skipCells': { return 'allowed format: Es. 10,11'}
        case 'stopColumn' : { return 'Please enter only letters'}
      }
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.editUrl = queryParams.get('url');
      this.loadData();
    });
  }

  loadData() {

    if (this.editUrl) {
      this.serviceDomain.getDomainFormats().pipe(
        switchMap(response => {
          this.domains = response._embedded.domainFormats;
          return this.service.get<DataFormat>(this.editUrl);
        }),
        switchMap(response => {
          this.dataFormat = response;

            //gestisco la possibilità che sia null - da verificare
          this.dataFormat.stopConfig = this.dataFormat.stopConfig ? this.dataFormat.stopConfig : {};
          //console.log("DataFormat", this.dataFormat.stopConfig)

          if(this.dataFormat.stopConfig.stopIfEmpty == true ){ 
            this.selectedStop = 0;
          } else {this.selectedStop = 1;}
          
          return this.service.get<DomainFormat>(this.dataFormat._links.targetFormat.href);
        })
      ).subscribe(response => {        
        this.selectedDomain = response._links.self.href;
        this.currentDomain = this.selectedDomain;
        this.onFormatLoaded.emit(this.dataFormat);
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });

    } else {
      this.serviceDomain.getDomainFormats().subscribe(response => {
        this.domains = response._embedded.domainFormats;
      })
    }

  }

  save() {
    if(this.selectedStop == 0 ){ 
      this.dataFormat.stopConfig.stopIfEmpty = true;
    } else this.dataFormat.stopConfig.stopIfEmpty = false;

    if (this.dataFormat._links) {
      let pipeObservable = this.service.updateObject<DataFormat>(this.dataFormat._links.self.href, this.dataFormat);

      /* Se è stato cambiato il domain di riferimento effettuiamo anche la chiamata per associarlo */
      if (this.currentDomain != this.selectedDomain) {
        pipeObservable = pipeObservable.pipe(
          switchMap(response => {
            return this.service.linkObject(response._links.targetFormat.href, this.selectedDomain);
          })
        );
      }

      pipeObservable.subscribe(response => {
    
        this._snackBar.open('Formato modificato con successo');
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });

    } else {
      this.service.createDataFormat(this.dataFormat).subscribe(response => {
        this.dataFormat = response;
        /* In aggiunta effettuiamo sempre la chiamata per associare format a domain */
        this.service.linkObject(response._links.targetFormat.href, this.selectedDomain).subscribe(response => {
          this._snackBar.open('Formato aggiunto con successo');
          this.onFormatLoaded.emit(this.dataFormat);
        }, error => {
          this.errorComponent.showError(ErrorHandler.getError(error));
        })
      }, error => {
        this.errorComponent.showError(ErrorHandler.getError(error));
      });
    }
  }



}