import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { FormInfluencerComponent } from './components/form-influencer/form-influencer.component';
import { UploadComponent } from './components/upload/upload.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AuthGuard } from './guard/auth.guard';
import { EmailAccountListComponent } from './components/email-account/list-component/email-account-list.component';
import { DataFormatComponent } from './components/data-format/list-component/data-format.component';
import { AppConstants } from './shared/app-constants';
import { TabsDataformatComponent } from './components/data-format/tabs-dataformat/tabs-dataformat.component';
import { TabsEmailComponent } from './components/email-account/tabs-email/tabs-email.component';
import { SamlLoginComponent } from './components/saml-login/saml-login.component';
import { AdminGuard } from './guard/admin.guard';
import { UserGuard } from './guard/user.guard';
import { NoCredentialsComponent } from './shared/no-credentials/no-credentials.component';


const routes: Routes = [
  //{ path:  AppConstants.Paths.HOME, component: HomeComponent, canActivate: [AuthGuard] },
  { path:  AppConstants.Paths.HOME, component: UploadComponent , canActivate: [UserGuard]}, 
  { path:  "localAuth", component: LoginPageComponent}, 
  //{ path:  AppConstants.Paths.INFLUENCER, component: FormInfluencerComponent, canActivate: [AuthGuard] },
   //{ path:  AppConstants.Paths.UPLOAD, component: UploadComponent, canActivate: [AuthGuard] },
  { path:  AppConstants.Paths.LOGIN, component: SamlLoginComponent },
  {
    path: AppConstants.Paths.ACCOUNT_LIST,
    data: {
      breadcrumb: 'Email boxes'
    },
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        pathMatch: "full",
        component: EmailAccountListComponent, canActivate: [AdminGuard],
        data: {
          breadcrumb: null
        }
      },
      {
        path: AppConstants.Paths.ACCOUNT_SAVE, component: TabsEmailComponent, canActivate: [AdminGuard]
        , data: {
          breadcrumb: 'Manage email'
        }
      }

    ]
  },
  {
    path: AppConstants.Paths.DATA_FORMAT,
    canActivate: [AdminGuard],
    data: {
      breadcrumb: 'Format list'
    },
    children: [
      {
        path: '',
        pathMatch: "full",
        component: DataFormatComponent, canActivate: [AdminGuard],
        data: {
          breadcrumb: null
        }
      },
      {
        path: AppConstants.Paths.DATA_FORMAT_SAVE, component: TabsDataformatComponent, canActivate: [AdminGuard]
        , data: {
          breadcrumb: 'Manage format'
        }
      },
     
    ]
  },
  { path: '', redirectTo: '/'+ AppConstants.Paths.HOME, pathMatch: 'full' }, // redirect to `home`
  { path: AppConstants.Paths.NO_CREDENTIAL, pathMatch: 'full', component: NoCredentialsComponent },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },  // Wildcard route for a 404 page

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
