<div *ngIf="totalElements?.length == 0 && !startedWizard" class="emptyContainer">
    <span>No cell configured for this format</span>
    <div class="addContainer" (click)="add()">
        <button mat-flat-button>Aggiungi</button>
    </div>

    <div class="form-actions" >
        <button mat-flat-button color="primary" (click)="startWizard()">Avvia wizard</button>
    </div>
</div>
<app-import-file [hidden]="!startedWizard" format="" (onDataResponse)="loadDataSource($event)"></app-import-file>
<div *ngIf="totalElements?.length > 0">
    <div class="controls">

        <button mat-icon-button color="primary" (click)="prevPage()" [disabled]="currentPage == 0">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <span class="pageProgress">{{currentPage+1}}/{{maxPage+1}}</span>
        <button mat-icon-button color="primary" (click)="nextPage()" [disabled]="currentPage >= maxPage">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>

    <form #repCellForm="ngForm">
        <app-repcell *ngFor="let cell of pageElements; let i = index" [type]="dataFormat?.type" [cell]="cell" [index]="i" [wizard]="startedWizard" [domainAttributes]="domainAttributes"
            (onDelete)="onDeleteCell($event)" [transformers]="dataTransformers"></app-repcell>

        <div class="addContainer" (click)="add()">
            <button mat-flat-button>Add</button>
        </div>
        <div class="legend">
            <div class="line">
                <mat-icon>playlist_add_check</mat-icon><span>Field inside normal column</span>
            </div>
            <div class="line">
                <mat-icon>playlist_add</mat-icon><span>Field inside json column</span>
            </div>
        </div>
        <div class="form-actions">
            
            <button mat-flat-button color="primary" (click)="save()" [disabled]="!repCellForm.form.valid">Save</button>
        </div>
    </form>
</div>