<div class="transformers-list-component">

    <button mat-flat-button color="primary" (click)="addTransformer()">Add</button>
    
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Name </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Type </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.type}} </td>
            </ng-container>

            <ng-container matColumnDef="datePattern">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> DatePattern</th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.datePattern}} </td>
            </ng-container>

             <ng-container matColumnDef="separator">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Separator </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.separator}} </td>
            </ng-container> 

            <ng-container matColumnDef="replace">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Replace </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.replaceText}} </td>
            </ng-container>

            <ng-container matColumnDef="replaceWith">
                <th mat-header-cell *matHeaderCellDef class="no-mobile"> Replace with </th>
                <td mat-cell *matCellDef="let element" class="no-mobile"> {{element.replaceWith}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-align-center"> Actions </th>
                <td mat-cell *matCellDef="let element" class="text-align-center">
                    <button mat-icon-button (click)="edit(element)" color="primary">
                        <mat-icon class="icons" aria-hidden="false" aria-label="Edit">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="openDeleteDialog(element)" [disabled]="element.enabled"
                        color="primary">
                        <mat-icon class="icons" aria-hidden="false" aria-label="Delete">
                            delete</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
   
</div>