import { HrefLink } from '../interface/generic-links';
import { DataFormat } from './dataFormat-model';
import { Hateoas } from './hateos';

export class EmailAccount extends Hateoas<EmailAccountLinks>{ 
   name: string;
   enabled: boolean;
   config = new ConfigModel();
}

export interface EmailAccountLinks {
   folders: HrefLink;
}
export interface EmailAccountGetList {
   emailAccounts: EmailAccount[];
} 

export class ConfigModel {
   host: string;
   port: number;
   tls: boolean;
   ssl: boolean;
   username: string;
   password: string;
};

export class EmailFolder extends Hateoas<EmailFolderLinks>{ 
   folder: string = 'INBOX';
   searchTerms: ConfigSearchTerms = new ConfigSearchTerms();
   format?: DataFormat; 
};

export class ConfigSearchTerms {
   fromTerm: string;
   subject: string;
   body: string;
};

export interface EmailFolderLinks {
   format: HrefLink;
   account: HrefLink;
}
export interface EmailFolderGetList {
   emailFolders: EmailFolder[];
} 