import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from '../dialog/dialog.component';
import { TableListLazyComponent } from './table-list-lazy.component';

@Component({
    selector: 'table-list',
    template: ``
})
export abstract class TableListComponent<T> extends TableListLazyComponent<T> implements OnInit{

    pageIndex = 0;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    constructor(
        protected dialog: MatDialog
    ) {
        super(dialog);
    }

    ngOnInit(): void {
        this.loadData();
    }
    
    pageEvent(event: { pageIndex: number; pageSize: number; }) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadData();
    }

    abstract loadData();
}