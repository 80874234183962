import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { RepeatableCells } from 'src/app/models/dataFormat-model';
import { ImportFile } from 'src/app/models/importFile-model';
import { DataFormatService } from 'src/app/services/data-format.service';
import { AppConstants } from 'src/app/shared/app-constants';
import { RepcellContainerComponent } from '../repcell-container/repcell-container.component';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit {
  fileName:string;
  file:any;
  importFile: ImportFile = new ImportFile();
  formatTypes = AppConstants.formatTypes;
  dataFormatId:string;

  @Output() onDataResponse = new EventEmitter<RepeatableCells[]>();

  constructor(private service: DataFormatService) { }

  ngOnInit(): void {

  }

  initFormat(dataFormatId:string) {
    this.dataFormatId = dataFormatId;
  }

  fileInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    if(this.file != undefined) {
      this.fileName = this.file.name;
    }
  }

  onFormatSelected(event) {
    let value = event.value;
    if(value == 'CSV') {
        this.importFile.headerIndex = 1;
    }
  }

  uploadFile() {
    this.service.wizardRepCells(this.dataFormatId, this.file, this.importFile).subscribe(response => {
      this.onDataResponse.emit(response);
    });
  }


  field = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.field.hasError('required')) {
      return 'Required field';
    }
  }

}
