<div *ngIf="totalElements?.length == 0" class="emptyContainer">
    <span>Nessuna cella configurata per questo formato</span>
    
    <div class="addContainer" (click)="add()">
        <button mat-flat-button >Aggiungi</button>
    </div>
</div>
<div *ngIf="totalElements?.length > 0">
    <div class="controls">
       
        <button mat-icon-button color="primary" (click)="prevPage()" [disabled]="currentPage == 0">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <span class="pageProgress">{{currentPage+1}}/{{maxPage+1}}</span>
        <button mat-icon-button color="primary" (click)="nextPage()" [disabled]="currentPage >= maxPage">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>

    <form #cellForm="ngForm">
        <app-cell-item *ngFor="let cell of pageElements; let i = index" [cell]="cell" [index]="i"
            (onDelete)="onDeleteCell($event)" [transformers]="dataTransformers"></app-cell-item>
            <div class="addContainer" (click)="add()">
                <button mat-flat-button >Add</button>
            </div>   
            <div class="form-actions">
                <button mat-flat-button color="primary" (click)="save()" [disabled]="!cellForm.form.valid">Save</button>
            </div> 
    </form>
</div>