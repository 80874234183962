import { SelfLink, HrefLink } from '../interface/generic-links';
export class Hateoas<T> {
    _links?: SelfLink & T;
    id?: number;
    getIdBySelf(): number {
        if(this._links && this._links.self)
            return Number(this._links.self.href.split("/").reverse()[0]);
        return null;
    }

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}