import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Account } from '../models/account-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: string;
  account: Account;
  
  constructor(private http: HttpClient) { 
    this.token = localStorage.getItem('token');
    let accountStr = localStorage.getItem('account');
    if(accountStr != undefined) {
      this.account = JSON.parse(accountStr);
    }
    
  }

  login(username:string, password:string) : Observable<Account>{
    let credentials = {username:username,password: password};
    return this.http.post<Account>(environment.apiUrl+"/v1/login",credentials);
  }

  doPostLogin(response:Account) {
    //console.log('doPostLogin', response);
    this.account = response;
    this.token = response.jwt;
    localStorage.setItem ('token', this.token);
    localStorage.setItem ('account', JSON.stringify(this.account));
  }

  getToken() {
    //return localStorage.getItem('token');
    return this.token;
  }

  getUser() {
    return this.account;
  }

  isAuthenticated() {
    return this.token != null;
  }

  logout() {
    return this.http.get(environment.baseUrl+'/saml/logout');
  }

  doPostLogout() {
    this.token = null;
    localStorage.removeItem('token');
  }

  hasRole(role:string) {
      if(this.account != undefined && this.account.roles != undefined) {
        return this.account.roles.includes('ROLE_'+role);
      }

      return false;
      
  }

  samlLogin(){
    return this.http.get(environment.baseUrl+'/auth/token',{withCredentials: true });
  }
}
