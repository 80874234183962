<div class="responsive-enel tabs-email-component">
    <app-breadcrumb></app-breadcrumb>
    <mat-card>
        <mat-tab-group dynamicHeight>
            <mat-tab label="Connection parameters">
                <div class="tab-padding">
                    <app-email-account (onAccountLoaded)="onAccountLoaded($event)"></app-email-account>
                </div>
            </mat-tab>
            <mat-tab label="Formats" [disabled]="!emailAccount?._links" >
                <div class="tab-padding">
                    <app-email-folder *ngIf="emailAccount._links" [account]="emailAccount"></app-email-folder>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>