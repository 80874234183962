import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
    selector: 'table-list-lazy',
    template: ``
})
export abstract class TableListLazyComponent<T> {

    dataSource: MatTableDataSource<T> = new MatTableDataSource();
    
    constructor(
        protected dialog: MatDialog
    ) {
    }
    
    openDeleteDialog(obj:T) {
        let action = 'Delete';
        const dialogRef = this.dialog.open(DialogComponent, {
            data: { action: action, obj: obj }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.event == 'Delete') {
                this.delete(result.data);
            }
        });
    }

    abstract delete(obj: T);

    abstract edit(obj: T);
}