<div class="grow-container">
   
    <mat-form-field appearance="outline" class="grow-width-field">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="cell.type" #cellType=ngModel name="cellType[{{index}}]"  (ngModelChange)="selectionChange(cellType)">
            <mat-option></mat-option>
            <mat-option *ngFor="let cellType of cellTypes" [value]="cellType">{{cellType}}</mat-option>
        </mat-select>
        <mat-error *ngIf="cellType.invalid">{{getErrorMessage(cellType)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field" *ngIf="cell.type == 'CONSTANT'" >
        <mat-label>Const value</mat-label>
        <input matInput placeholder="Const value" [(ngModel)]="cell.constValue" name="constValue[{{index}}]" #constValue=ngModel required>
        <mat-error *ngIf="constValue.invalid">{{getErrorMessage(constValue)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field" *ngIf="cell.type == 'NORMAL'">
        <mat-label>Position</mat-label>
        <input matInput placeholder="Es. A2 or B3" [(ngModel)]="cell.position" name="position[{{index}}]" #position=ngModel required >
        <mat-error *ngIf="position.invalid">{{getErrorMessage(position)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
        <mat-label>Target name</mat-label>
        <input matInput placeholder="Target name" [(ngModel)]="cell.targetName" #targetName=ngModel name="targetName[{{index}}]" 
        oninput="this.value = this.value.toLowerCase()"
        required>
        <mat-error *ngIf="targetName.invalid">{{getErrorMessage(targetName)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
        <mat-label>Target type</mat-label>
        <mat-select [(ngModel)]="cell.targetType" name="targetType[[{{index}}]" required #targetType=ngModel>
            <mat-option *ngFor="let dataType of cellTargetTypes" [value]="dataType">{{dataType}}</mat-option>
        </mat-select>
        <mat-error *ngIf="targetType.invalid">{{getErrorMessage(targetType)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
        <mat-label>Transformer</mat-label>
        <mat-select [(ngModel)]="selectedTransformer" name="transformer[{{index}}]" (selectionChange)="onSelectedTransformer($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let transformer of transformers" [value]="transformer.id">{{transformer.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-icon-button color="primary" (click)="deleteCell()">
        <mat-icon>delete</mat-icon>
    </button>
</div> 