<div class="responsive-enel upload-component">
  <form #uploadForm="ngForm" (ngSubmit)="uploadFile()">
    <mat-card class="mat-elevation-z2">
    
      <mat-form-field>
        <mat-label>Choose a format</mat-label>
        <mat-select [(ngModel)]="selectedTarget" required name="targetFormat" (selectionChange)="onDomainSelected()">
          <mat-option *ngFor="let targetFormat of domainFormats" [value]="targetFormat">{{targetFormat.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Choose a configuration</mat-label>
        <mat-select [(ngModel)]="selectedFormat" required name="format" [disabled]="selectedTarget == undefined">
          <mat-option *ngFor="let format of formats" [value]="format">{{format.name}}</mat-option>
        </mat-select>
         <mat-hint *ngIf="selectedFormat != undefined">{{selectedFormat.description}} - {{selectedFormat.type}}</mat-hint> 
      </mat-form-field>
      

      <p class="file-container">
        <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="fileInputChange($event)" accept=".csv,.xls,.xlsx" />
        <mat-label>Filename: {{fileName}}</mat-label>
        <button type="button" mat-stroked-button color="accent" (click)="fileInput.click()" [disabled]="selectedFormat == undefined">Choose a file</button>
      </p>
    </mat-card>
    <app-error-message></app-error-message>
    <button class="submit" mat-flat-button color="primary" type="submit" [disabled]="!uploadForm.form.valid || file == undefined">Process</button>
  </form>
</div>