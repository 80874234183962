<div class="email-account-component">
    <form #socialForm="ngForm"
          (ngSubmit)="save()"
         >

        <div class="grow-container">
            <mat-form-field appearance="outline" class="grow-width-field">
                <mat-label>Name</mat-label>
                <input 
                maxlength="20" 
                matInput placeholder="Name" 
                [(ngModel)]="emailAccount.name" 
                name="name" 
                #name= ngModel
                required>
               <mat-error *ngIf="name.invalid">{{getErrorMessage(name)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow-width-field grow-middle-field">
                <mat-label>Host</mat-label>
                <input 
                matInput 
                placeholder="Host" 
                [(ngModel)]="emailAccount.config.host" 
                name="host"
                #host= ngModel 
                required>
                <mat-error *ngIf="host.invalid">{{getErrorMessage(host)}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow-width-field">
                <mat-label>Port</mat-label>
                <input 
                matInput placeholder="Port" 
                [(ngModel)]="emailAccount.config.port" 
                name="port"
                [pattern]="numberPattern" 
                #port= ngModel 
                required>
                <mat-error *ngIf="port.invalid">{{getErrorMessage(port)}}</mat-error>
            </mat-form-field>
        </div>


        <div class="grow-container">
            <mat-form-field appearance="outline" class="grow-width-field" [ngClass]="{'no-padding-field':emailAccount.config.username == emailAccount.config.username }">
                <mat-label>Username</mat-label>
                <input 
                matInput 
                [(ngModel)]="emailAccount.config.username" 
                name="username"
                [pattern]="userPattern"
                required
                #username="ngModel">
                <mat-error *ngIf="username.invalid">{{getErrorMessage(username)}}</mat-error>
                <!-- <mat-error *ngIf="username.hasError('pattern')">Please enter a valid email address Es. enel@test.it</mat-error>                       
                <mat-error *ngIf="username.hasError('required')">Campo Obbligatorio</mat-error> -->
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="grow-width-field">
            <mat-label>Password</mat-label>
            <input 
            matInput 
            placeholder="Password" 
            [(ngModel)]="emailAccount.config.password"
            [type]="fieldTextType ? 'text' : 'password'" 
            name="password" 
            [pattern]="pwCtrlPattern"
            required
            #password="ngModel">
            <button type="button" mat-button matSuffix mat-icon-button aria-label="Toggle visibility"
                (click)="togglePasswordVisible()">
                <mat-icon *ngIf="!fieldTextType">visibility</mat-icon>
                <mat-icon *ngIf="fieldTextType">visibility_off</mat-icon>
            </button>
            <!-- <mat-error *ngIf="password.hasError('pattern')">Please enter a correct password</mat-error>                       
            <mat-error *ngIf="password.hasError('required')">Campo Obbligatorio</mat-error> -->
                     <mat-error *ngIf="password.invalid">{{getErrorMessage(password)}}</mat-error>                     
        </mat-form-field>

        <mat-checkbox [(ngModel)]="emailAccount.enabled" name="enabled" [disabled]="true" *ngIf="emailAccount._links">
            Enabled
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="emailAccount.config.ssl" name="ssl">
            SSL
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="emailAccount.config.tls" name="tls">
            TLS
        </mat-checkbox>


        <app-error-message></app-error-message>
        <div class="form-actions">
            <button mat-flat-button color="warn" type="button" *ngIf="!emailAccount.enabled && emailAccount._links"
                (click)="activate(emailAccount)">Enable</button>
            <button mat-flat-button color="warn" type="button" *ngIf="emailAccount.enabled && emailAccount._links"
                (click)="deactivate(emailAccount)">Disable</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="!socialForm.form.valid">Save</button>
        </div>
    </form>
</div>