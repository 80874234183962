import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailAccount } from 'src/app/models/emailAccount-model';
import { EmailAccountService } from 'src/app/services/email-account.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { TableListComponent } from 'src/app/shared/table-list/table-list.component';
import { AppConstants } from 'src/app/shared/app-constants';

@Component({
  selector: 'app-email-account-list',
  templateUrl: './email-account-list.component.html',
  styleUrls: ['./email-account-list.component.scss']
})
export class EmailAccountListComponent extends TableListComponent<EmailAccount> {
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  addRouteLink:string;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['name', 'enabled', 'host', 'port', 'username', 'actions'];
  
  constructor(
    private service: EmailAccountService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    protected dialog: MatDialog
  ) {
    super(dialog);
    this.addRouteLink = AppConstants.Paths.ACCOUNT_SAVE;
  }

  loadData() {
    this.service.getAccountLists(this.pageSize, this.pageIndex).subscribe(result => {
      this.dataSource = new MatTableDataSource(result._embedded.emailAccounts);
      this.paginator.length = result.page.totalElements;
      this.paginator.pageSize = result.page.size;
    });
  }

  edit(account:EmailAccount) {
    this.router.navigate([AppConstants.Paths.ACCOUNT_SAVE], {  relativeTo: this.route, queryParams:{ url: account._links.self.href }});
  }

  delete(account: EmailAccount) {
      this.service.deleteObject(account._links.self.href).subscribe(result => {
        this._snackBar.open('Account eliminato con successo');
        this.loadData();
      })
    }
}


